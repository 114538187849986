/* eslint-disable */
//@ts-nocheck
export const useBrandingForm = (props: any) => {
  /* eslint-disable */
  //@ts-nocheck
  const onSubmit = (data: any) => {
    //
  };

  return {
    onSubmit,
  };
};
